import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    heroes: [],
    favHeroes: [],
  },
});

//vuex
