<template>
  <div class="container">
    <h1>My Marvel Hero</h1>
    <p>View your favorite Marvel heros</p>
  </div>
</template>
<script>
export default {
  name: "Header",
};
</script>
